import PropTypes from "prop-types";
import MuiCheckbox from "@mui/material/Checkbox";

const CheckBox = ({ checked, className, disabled, id, inputProps, name, onChange, sx, value }) => (
  <MuiCheckbox
    checked={checked}
    className={className}
    disableRipple
    disabled={disabled}
    id={id}
    inputProps={inputProps}
    name={name}
    onChange={onChange}
    sx={{
      paddingTop: 0,
      paddingBottom: 0,
      ...sx,
    }}
    value={value}
  />
);

CheckBox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,

  inputProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  sx: PropTypes.object,
  value: PropTypes.string.isRequired,
};

CheckBox.defaultProps = {
  checked: false,
  className: undefined,
  disabled: false,
  inputProps: {},
  onChange: () => {},
  sx: {},
};

export default CheckBox;
