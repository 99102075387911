import PropTypes from "prop-types";
import { useField } from "formik";
import SelectMultiple from "components/SelectMultiple";

const FormikSelectMultiple = ({ name, filterOptions, ...rest }) => {
  const [{ value }, { touched, error }, { setValue }] = useField(name);

  return (
    <SelectMultiple
      error={touched && error}
      filterOptions={filterOptions}
      onChange={setValue}
      values={value}
      {...rest}
    />
  );
};

FormikSelectMultiple.propTypes = {
  filterOptions: PropTypes.func,
  name: PropTypes.string.isRequired,
};

export default FormikSelectMultiple;
