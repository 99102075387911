import { useState } from "react";
import requestToServer from "utils/requestToServer";
import transformKeysToSnakeCase from "utils/transformKeysToSnakeCase";
import { isString } from "lodash";
import View from "./View";

const Universal = ({
  method,
  action,
  data: {
    ethnicity,
    ethnicityOptions,
    ethnicityQuestionEnabled,
    ethnicityRatherNotSayOption,
    language,
    languageOptions,
    languageRatherNotSayOption,
    occupation,
    occupationOptions,
    occupationQuestionEnabled,
    raceAlwaysShowLastOption,
    raceOptions,
    raceQuestionEnabled,
    raceRatherNotSayOption,
    races,
  },
}) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const ethnicityValue = (values) => {
    if (isString(values.ethnicity)) {
      return { ethnicity: values.ethnicity };
    } else {
      return { ethnicities: values.ethnicityRatherNotSay ? [ethnicityRatherNotSayOption.value] : values.ethnicity };
    }
  };

  const handleSubmit = (values) => {
    setSubmitting(true);
    requestToServer({
      method,
      path: action,
      body: transformKeysToSnakeCase({
        demographics: {
          language: values.languageRatherNotSay ? languageRatherNotSayOption.value : values.language,
          occupation: values.occupation,
          races: values.raceRatherNotSay ? [raceRatherNotSayOption.value] : values.races,
          ...ethnicityValue(values),
        },
      }),
    });
  };

  return (
    <View
      ethnicity={ethnicity}
      ethnicityOptions={ethnicityOptions}
      ethnicityQuestionEnabled={ethnicityQuestionEnabled}
      ethnicityRatherNotSayOption={ethnicityRatherNotSayOption}
      isSubmitting={isSubmitting}
      language={language}
      languageOptions={languageOptions}
      languageRatherNotSayOption={languageRatherNotSayOption}
      occupation={occupation}
      occupationOptions={occupationOptions}
      occupationQuestionEnabled={occupationQuestionEnabled}
      onSubmit={handleSubmit}
      raceAlwaysShowLastOption={raceAlwaysShowLastOption}
      raceOptions={raceOptions}
      raceQuestionEnabled={raceQuestionEnabled}
      raceRatherNotSayOption={raceRatherNotSayOption}
      races={races}
    />
  );
};

export default Universal;
